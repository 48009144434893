import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import sagas from "./sagas";
import { createReduxHistoryContext, RouterState } from "redux-first-history";
import { globalPersistReducer, IGlobal } from "./saga/global";
import { facebookPersistReducer, IFacebook } from "./saga/facebook";

import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { PROFILE_IMG } from "./saga/facebook/constants";
import { persistReducer, persistStore } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    //other options if needed
  });

const sagaMiddleware = createSagaMiddleware();

export interface IApplicationState {
  router?: RouterState,
  global?: IGlobal,
  facebook?: IFacebook,
}

const stateSyncMiddleware = createStateSyncMiddleware({
  whitelist: [PROFILE_IMG]
});

const pConfig = {
  key: 'root',
  storage: storage,
  version: 0,
  whitelist: ['global', 'facebook'],
};

const reducer = persistReducer(pConfig, combineReducers({
    router: routerReducer,
    global: globalPersistReducer,
    facebook: facebookPersistReducer,
  }));

export const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(stateSyncMiddleware),
    applyMiddleware(routerMiddleware),
  )
);

initMessageListener(store);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

export const history = createReduxHistory(store);