import { Button, Grid, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import StyleBox from "../../components/style_box/StyleBox";
import Image from "../../assets/magic-wrong.png";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";
import { store } from "../../redux/store";
import { start } from "../../redux/saga/facebook";

const CustomizedTypography = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
`;

const CustomImage = styled("img")`
  height: 300px;
`;

function OopsPage() {
  const dispatch = useDispatch();

  // Hack for cross-tab saga execution
  const profile_image = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.facebook.profile_image;
  });

  const matches = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.global.matches;
  });

  useEffect(() => {
    if (matches.length > 0) {
      dispatch(push("/match"));
    }
  }, [matches, dispatch]);

  useEffect(() => {
    if (profile_image !== null) {
      dispatch(start(profile_image));
    }
  }, [profile_image, dispatch]);

  const goBack = () => {
    dispatch(push("/"));
  }

  return (
    <StyleBox>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="primary"
        sx={{
          height: {
            xs: `calc(100vh - 250px)`,
            sm: `600px`
          }
        }}
      >
        <Grid item sx={{
          width: {
            xs: `calc(100vw - 20px)`,
            sm: `340px`,
          }
        }}>
          <CustomizedTypography align="center">
            Oops!!
          </CustomizedTypography>
          <Typography variant="body1" gutterBottom component="div" align="center">
            We cannot process your request at the moment.
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: "16px", marginTop: "40px" }}>
          <CustomImage src={Image} />
        </Grid>
        <Grid item>
            <Button onClick={goBack}>Go Back!</Button>
        </Grid>
      </Grid>
    </StyleBox>
  );
}

export default OopsPage;
