import { Paper } from "@mui/material";
import React from "react";
import { StyleBoxProps } from "./types";

const style = {
  mainContainer: {
    overflow: "hidden",
  },
};

function StyleBox(props: StyleBoxProps) {
  return (
    <Paper elevation={24} style={style.mainContainer}>
      {props.children}
    </Paper>
  );
}

export default StyleBox;
