import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { code } from "../../redux/saga/facebook/actions";
import { store } from "../../redux/store";

export default function FacebookPage() {
    const dispatch = useDispatch();

    const profileImg = useSelector((state: ReturnType<typeof store.getState>) => {
        return state.facebook.profile_image;
    });

    const [searchParams] = useSearchParams();

    const accessCode = searchParams.get('code');

    useEffect(() => {
        if (accessCode !== null) {
            dispatch(code(accessCode));
        }
    }, [accessCode, dispatch]);

    useEffect(() => {
        if (profileImg !== null) {
            setTimeout(()=>{
                window.close();
            }, 250);
        }
    }, [profileImg]);

    return (
        <div></div>
    )
}