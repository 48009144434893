import { CHALLENGE, PROFILE_IMG, TOKEN } from './constants';
import { IFacebook, FacebookActions } from "./types";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE: IFacebook = {
  profile_image: null,
  token: null,
  challenge: null,
  
  _persist: undefined,
};

export const facebookReducer = (
  state = INITIAL_STATE,
  action: {
    type: string;
    payload: FacebookActions;
  }
): IFacebook => {
  switch (action.type) {
    case TOKEN:
      return {
        ...state,
        token: action.payload as string,
        challenge: null,
      };
    case PROFILE_IMG:
        return {
            ...state,
            profile_image: action.payload as string,
        }
    case CHALLENGE:
      return {
        ...state,
        challenge: action.payload as string,
      }
    default:
      return {
        ...state,
      };
  }
};

export const facebookPersistReducer = persistReducer({
  key: 'facebook',
  storage: storage,
  whitelist: ['challenge'],
}, facebookReducer);
