import {
  Facebook,
  Folder,
  PhotoCamera,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { start as startUpload } from "../../redux/saga/upload";
import { start as startCamera } from "../../redux/saga/camera";
import StyledCamera from "../styled_camera/StyledCamera";
import UploadMenuProps from "./types";
import { getChallengeAndVerifier, getFacebookAuthorizeURI } from "../../facebook/facebook";
import { challenge } from "../../redux/saga/facebook";
import { startMatch } from "../../redux/saga/global";

const CustomizedMenuItem = styled(MenuItem)`
  width: 200px;
  height: 50px;
`;

const FileInput = styled("input")({
  display: "none",
});

function UploadMenu(props: UploadMenuProps) {
  const openMenu = Boolean(props.anchorEl);

  const [openModal, setOpenModal] = React.useState(false);

  const fileRef = React.useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch();

  const closeModalFn = () => {
    setOpenModal(false);
  };

  const openModalFn = () => {
    setOpenModal(true);
  };

  const initFacebookLogin = () => {
    const [ch, verifier] = getChallengeAndVerifier();
    dispatch(challenge(verifier));
    openInNewTab(getFacebookAuthorizeURI(ch));
    dispatch(startMatch("facebook"));
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      startUpload({
        file: e.target.files![0],
      })
    );
    dispatch(startMatch("file"));
  };

  const takePhoto = (photo: string) => {
    dispatch(
      startCamera({
        file: photo,
      })
    );
    closeModalFn();

    dispatch(startMatch("camera"));
    props.onClose();
  };

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer,width=300,height=250')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Menu
      id="upload-menu"
      anchorEl={props.anchorEl}
      open={openMenu}
      onClose={props.onClose}
    >
      <StyledCamera
        onTakePhoto={takePhoto}
        onClose={closeModalFn}
        open={openModal}
      />
      <CustomizedMenuItem divider onClick={openModalFn}>
        <ListItemIcon>
          <PhotoCamera></PhotoCamera>
        </ListItemIcon>
        <ListItemText>Take a Picture</ListItemText>
      </CustomizedMenuItem>
      <CustomizedMenuItem divider onClick={closeModalFn}>
        <label htmlFor="file-uploader" style={{ display: "flex" }}>
          <FileInput
            accept="image/*"
            id="file-uploader"
            type="file"
            ref={fileRef}
            onChange={handleFileSelect}
            multiple={false}
          />
          <ListItemIcon>
            <Folder></Folder>
          </ListItemIcon>
          <ListItemText>From Device</ListItemText>
        </label>
      </CustomizedMenuItem>
      <CustomizedMenuItem onClick={initFacebookLogin}>
        <ListItemIcon>
          <Facebook></Facebook>
        </ListItemIcon>
        <ListItemText>From Facebook</ListItemText>
      </CustomizedMenuItem>
    </Menu>
  );
}

export default UploadMenu;
