import { push } from "redux-first-history";
import { SagaIterator } from "redux-saga";
import { delay, put, takeEvery } from "redux-saga/effects";
import { startMatch } from "./actions";
import { START_MATCH } from "./constants";

function* startMatchWorker(action: ReturnType<typeof startMatch>): SagaIterator{
    yield put(push('/loading'));
}

export default function* root(): SagaIterator {
  yield takeEvery(START_MATCH, startMatchWorker);
}