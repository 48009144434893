import { v4 as uuidv4 } from 'uuid';
import { facebookClientID, facebookGraphURI, facebookRedirectURI } from '../constants/constants';
import sha256 from "fast-sha256";
import { Buffer } from 'buffer';

export function getFacebookAuthorizeURI(challenge: string) {
    return `${facebookGraphURI}oauth/authorize?client_id=${facebookClientID}&scope=openid,public_profile&redirect_uri=${facebookRedirectURI}&response_type=code&code_challenge=${challenge}&code_challenge_method=S256`;
}

export function getFacebookExchangeURI(verifier: string, code: string) {
    return `${facebookGraphURI}oauth/access_token?client_id=${facebookClientID}&redirect_uri=${facebookRedirectURI}&code_verifier=${verifier}&code=${code}`
}

export function getChallengeAndVerifier(): [string, string] {
    const verifier = uuidv4() + "-" + uuidv4();
    const sha = sha256(Buffer.from(verifier))
    return [Buffer.from(sha).toString('base64').replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_") , verifier];
}

export function getFacebookPictures() {
    return `${facebookGraphURI}me/picture?type=large&redirect=false&height=200`;
}