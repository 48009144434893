import { file } from './../global/actions';
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import { getFacebookExchangeURI, getFacebookPictures } from "../../../facebook/facebook";
import { code, profileImg, token } from "./actions";
import { store } from "../../store";
import { shoeRankBaseURI } from "../../../constants/constants";
import { convertResponseToBase64 } from "../helpers/helpers";
import { push } from "redux-first-history";
import { matched } from "../global";
import { CODE, START } from "./constants";

export function* startProfileImageWorker(action: ReturnType<typeof profileImg>): SagaIterator {
  try {
    yield put(file(action.payload));
    const data = new FormData();
    data.append('url', action.payload);

    const matchRes: Response = yield call(
      fetch,
      shoeRankBaseURI + "/download_image",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    );

    if (!matchRes.ok) {
      throw new Error("not ok response");
    }
    const matches = yield call(matchRes.json.bind(matchRes));

    const matchingShoes: string[] = [];
    for (var i = 0; i < matches.length; i++) {
      const match = matches[i][1];
      if (matchingShoes.indexOf(match) === -1) {
        const shoeURI = shoeRankBaseURI + "/" + match;

        const res: Response = yield call(fetch, shoeURI, {
          method: "GET",
        });
        matchingShoes.push(yield call(convertResponseToBase64, res));
      }
    }
    yield put(matched(matchingShoes));
    yield put(push("/match"));
  } catch (e: any) {
    // redirect to something
    yield put(push("/oops"));
  }
}

export function* startTokenExchangeWorker(action: ReturnType<typeof code>): SagaIterator {
    const verifier: string = yield select((state: ReturnType<typeof store.getState>): string => {
        return state.facebook.challenge!;
    });

    const uri = getFacebookExchangeURI(verifier, action.payload);
    
    const res: Response = yield call(
        fetch,
        uri,
    )
    if (!res.ok) {
        return
    }
    const t = yield call(res.json.bind(res));
    const access_token: string = t['access_token'];

    yield put(token(access_token));

    const picRes: Response = yield call(
        fetch,
        getFacebookPictures(),
        {
            headers: {
                "Authorization" : `Bearer ${access_token}`
            }
        }
    )
    if (!picRes.ok) {
        return
    }
    const picture = yield call(picRes.json.bind(picRes));
    const picture_url: string = picture['data']['url'];

    yield put(profileImg(picture_url));
}

export default function* root(): SagaIterator {
    yield takeLatest(START, startProfileImageWorker);
    yield takeLatest(CODE, startTokenExchangeWorker);
}