import { start } from "./actions";
import { takeEvery, call, put } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { START } from "./constants";
import { push } from "redux-first-history";
import { FILE, MATCHED } from "../global/constants";
import { shoeRankBaseURI } from "../../../constants/constants";

const convertResponseToBase64 = (
  res: Response
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    res.blob().then((blob) => {
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  });
};

export function* startWorker(action: ReturnType<typeof start>): SagaIterator {
  var data = new FormData();

  var matches: string[][] = [[]];

  try {
    const blobRes: Response = yield call(fetch, action.payload.file);

    yield put({
      type: FILE,
      payload: action.payload.file,
    });

    const blob = yield call(blobRes.blob.bind(blobRes));

    data.append("file", blob);

    const res: Response = yield call(
      fetch,
      shoeRankBaseURI + "/get_shoe_rank",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    );

    if (!res.ok) {
      throw new Error("not ok response");
    }
    matches = yield call(res.json.bind(res));

    const matchingShoes: string[] = [];
    for (var i = 0; i < matches.length; i++) {
      const match = matches[i][1];
      if (matchingShoes.indexOf(match) === -1) {
        const shoeURI = shoeRankBaseURI + "/" + match;

        const res: Response = yield call(fetch, shoeURI, {
          method: "GET",
        });
        matchingShoes.push(yield call(convertResponseToBase64, res));
      }
    }
    yield put({
      type: MATCHED,
      payload: matchingShoes,
    });
    yield put(push("/match"));
  } catch (e: any) {
    // redirect to something
    yield put(push("/oops"));
  }
}

export default function* root(): SagaIterator {
  yield takeEvery(START, startWorker);
}
