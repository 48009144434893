import React from "react";
import "./App.css";
import { Typography, Grid, Button, useMediaQuery, Theme } from "@mui/material";
import { ReactComponent as Logo } from "./assets/wellpaired.svg";
import { ReactComponent as InstagramLogo } from "./assets/instagram.svg";
import Image from "./assets/background.png";
import LandingPage from "./pages/landing/Landing";
import LoadingPage from "./pages/loading/Loading";
import { Routes, Route, Link } from "react-router-dom";
import MatchPage from "./pages/match/Match";
import ComingSoon from "./components/coming_soon/ComingSoon";
import styled from "@emotion/styled";
import FacebookPage from "./pages/facebook/Facebook";
import OopsPage from "./pages/oops/Oops";

const styles = {
  mainContainer: {
    backgroundImage: `url(${Image})`,
    flexGrow: 1,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  mainContainerContent: {
    height: "100%",
  },
  appBarMd: {
    paddingLeft: "40px",
    paddingRight: "40px",
    height: "96px",
  },
  appBarSx: {
    paddingLeft: "10px",
    paddingRight: "10px",
    height: "96px",
  },
};

const CustomizedFooterTypography = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
`;

function App() {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [isComingSoon, setComingSoon] = React.useState(false);

  const openComingSoon = () => {
    setComingSoon(true);
  };

  const closeComingSoon = () => {
    setComingSoon(false);
  };

  return (
    <Grid
      container
      id="main"
      direction="column"
      alignItems="stretch"
      justifyContent="space-between"
    >
      <ComingSoon open={isComingSoon} onClose={closeComingSoon} />
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        id="header"
        gridRow={3}
        style={isSmallScreen ? styles.appBarSx : styles.appBarMd}
      >
        <Grid item>
          <Link to="/">
            <Logo />
          </Link>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button color="primary" onClick={openComingSoon}>
                Style Quiz
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={openComingSoon}>
                Sign Up/Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={styles.mainContainer}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={styles.mainContainerContent}
          className="noBg"
        >
          <Routes>
            <Route path="/loading" element={<LoadingPage />} />
            <Route path="/match" element={<MatchPage />} />
            <Route path="/facebook" element={<FacebookPage />} />
            <Route path="/oops" element={<OopsPage />} />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Grid>
      </Grid>
      <Grid
        container
        item
        style={{ height: "96px", paddingLeft: "10px" }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="secondary"
      >
        <Grid item xs={4} md={3}>
          <Grid
            container
            direction="row"
            className="secondary"
            justifyContent="space-evenly"
          >
            <Grid item>
              <CustomizedFooterTypography color="primary">
                ©2021 Style IQ
              </CustomizedFooterTypography>
            </Grid>
            <Grid item>
              <CustomizedFooterTypography color="primary">
                Terms & Conditions
              </CustomizedFooterTypography>
            </Grid>
            <Grid item>
              <CustomizedFooterTypography color="primary">
                Primary Policy
              </CustomizedFooterTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item bgcolor="secondary" xs={2}>
          <Grid
            container
            className="secondary"
            direction="row"
            justifyContent="space-evenly"
          >
            <Grid item>
              <CustomizedFooterTypography color="primary">
                <InstagramLogo />
              </CustomizedFooterTypography>
            </Grid>
            <Grid item>
              <CustomizedFooterTypography color="primary">
                styleiq.com
              </CustomizedFooterTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default App;
