import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import React from "react";
import Camera from "react-html5-camera-photo";
import StyledCameraProps from "./types";

function StyledCamera(props: StyledCameraProps) {
  const [showCamera, setShowCamera] = React.useState(true);

  const onTakePhoto = (data: string) => {
    setShowCamera(false);
    setTimeout(() => {
      props.onTakePhoto(data);
    }, 100);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ maxWidth: "none" }}>
      <DialogContent>
        {showCamera ? (
          <Camera onTakePhoto={onTakePhoto} isMaxResolution={true} />
        ) : (
          <CircularProgress color="success" />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default StyledCamera;
