import { all } from "redux-saga/effects";

import uploadSaga from "./saga/upload/saga";
import cameraSaga from "./saga/camera/saga";
import facebookSaga from "./saga/facebook/saga";
import globalSaga from "./saga/global/saga";

export default function* root() {
  yield all([globalSaga(), uploadSaga(), cameraSaga(), facebookSaga()]);
}
