import { start } from "./actions";
import { takeEvery, call, put } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { push } from "redux-first-history";
import { shoeRankBaseURI } from "../../../constants/constants";
import { file, matched } from "../global";
import { convertBase64, convertResponseToBase64 } from "../helpers/helpers";
import { START } from "./constants";

export function* startWorker(action: ReturnType<typeof start>): SagaIterator {
  var data = new FormData();

  data.append("file", action.payload.file);

  var matches: string[][] = [[]];

  try {
    const base64File = yield call(convertBase64, action.payload.file);

    yield put(file(base64File));

    const res: Response = yield call(
      fetch,
      shoeRankBaseURI + "/get_shoe_rank",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    );

    if (!res.ok) {
      throw new Error("not ok response");
    }
    matches = yield call(res.json.bind(res));

    const matchingShoes: string[] = [];
    for (var i = 0; i < matches.length; i++) {
      const match = matches[i][1];
      if (matchingShoes.indexOf(match) === -1) {
        const shoeURI = shoeRankBaseURI + "/" + match;

        const res: Response = yield call(fetch, shoeURI, {
          method: "GET",
        });
        matchingShoes.push(yield call(convertResponseToBase64, res));
      }
    }
    yield put(matched(matchingShoes));
    yield put(push("/match"));
  }  catch (e: any) {
    // redirect to something
    yield put(push("/oops"));
  }
}

export default function* root(): SagaIterator {
  yield takeEvery(START, startWorker);
}
