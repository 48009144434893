import { FILE, MATCHED, START_MATCH } from "./constants";
import { IGlobal, GlobalActions } from "./types";

import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE: IGlobal = {
  image: null,
  matches: [],
  match_type: null,
  _persist: undefined,
};

export const globalReducer = (
  state = INITIAL_STATE,
  action: {
    type: string;
    payload: GlobalActions;
  }
): IGlobal => {
  switch (action.type) {
    case MATCHED:
      return {
        ...state,
        matches: action.payload as string[],
      };
    case FILE:
      return {
        ...state,
        image: action.payload as string,
      };
    case START_MATCH:
      return {
        ...state,
        image: null,
        matches: [],
      }
    default:
      return {
        ...state,
      };
  }
};

const globalPersistConfig: PersistConfig<IGlobal> = {
  key: 'global',
  storage: storage,
  whitelist: [],
};

export const globalPersistReducer = persistReducer(globalPersistConfig, globalReducer);
