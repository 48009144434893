import { styled, experimental_sx as sx, } from "@mui/material/styles";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import StyleBox from "../../components/style_box/StyleBox";
import { store } from "../../redux/store";
import PlusImage from "../../assets/plus.svg";
import { Navigate } from "react-router-dom";

const CustomizedTypography = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
`;

const CustomizedHashtagTypography = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
`;

const CustomOriginalImage = styled("div", {
  skipSx: false,
})(
  sx({
    transform: 'rotate(-10.66deg)',
    border: '3px solid rgba(0, 0, 0, 1)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: {
      sm: '300px',
      xs: '100px',
    },
    width: {
      sm: '200px',
      xs: '66px'
    }
  })
);

const CustomPlusImage = styled("img")``;

const CustomMatchImage = styled("div", {
  skipSx: false,
})(
  sx({
    width: {
      xs: '60px',
      sm: '140px'
    },
    height: {
      xs: '60px',
      sm: '140px'
    },
    border: '3px solid rgba(0, 0, 0, 1)',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }),
);

export default function MatchPage() {
  const orgImg = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.global.image;
  });

  const match1 = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.global.matches[0];
  });

  const match2 = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.global.matches[1];
  });

  const match3 = useSelector((state: ReturnType<typeof store.getState>) => {
    return state.global.matches[3];
  });

  if (orgImg === null) {
    return <Navigate to="/" />;
  }

  return (
    <StyleBox>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="primary"
        sx={{
          width: {
            sm: `calc(100vw - 20px)`,
            md: `900px`,
          }
        }}
      >
        <Grid item xs="auto" sx={{ marginTop: {
          xs: "10px",
          md: "50px"
        } }}>
          <CustomizedTypography align="center">Tada!</CustomizedTypography>
          <CustomizedTypography align="center">
            What do you think?
          </CustomizedTypography>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            marginTop: "40px",
            marginBottom: "50px",
            flexGrow: 1,
            maxHeight: {
              md: "700px"
            },
            width: {
              sm: '100%'
            }
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="stretch"
            sx={{
              justifyContent:{
                xs: "flex-start",
                sm: "center",
              }
            }}
          >
            <Grid item xs={6} sm={4}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <CustomOriginalImage
                    sx={{
                      backgroundImage: `url(${orgImg!})`,
                    }}
                  />
                </Grid>
                <Grid item sx={{ marginTop: "25px" }}>
                  <CustomizedHashtagTypography align="center">
                    #Random #Awesome
                  </CustomizedHashtagTypography>
                  <CustomizedHashtagTypography align="center">
                    #Wow
                  </CustomizedHashtagTypography>
                </Grid>
                <Grid item sx={{ marginTop: "15px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      paddingX: "41px",
                      paddingY: "15px",
                      fontSize: "16px",
                    }}
                  >
                    Update Outfit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} sx={{
              display: {
                xs: 'none',
                sm: 'flex'
              }
            }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Grid item>
                  <CustomPlusImage src={PlusImage} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} sm={5} sx={{ 
              marginLeft: {
                xs: '15px',
                md: '0px'
              }
            }}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ height: "100%" }}
              >
                <Grid item>
                  <CustomizedHashtagTypography align="center">
                    Our Top 3 Picks for you
                  </CustomizedHashtagTypography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    sx={{ height: "100%" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        sx={{ 
                          height: "100%",
                        }}
                      >
                        <Grid item>
                          <CustomMatchImage
                            style={{
                              backgroundImage: `url(${match1})`,
                              transform: "rotate(-4.09deg)",
                            }}
                          />
                        </Grid>
                        <Grid item sx={{ paddingTop: "35px" }}>
                          <CustomMatchImage
                            style={{
                              backgroundImage: `url(${match2})`,
                              transform: "rotate(2.24deg)",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <Grid item>
                          <CustomMatchImage
                            style={{
                              backgroundImage: `url(${match3})`,
                              transform: "rotate(7.53deg)",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          paddingX: "38px",
                          paddingY: "15px",
                          fontSize: "16px",
                        }}
                      >
                        View more Match
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyleBox>
  );
}
