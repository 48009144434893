import { Dialog, DialogContent, Typography } from "@mui/material";
import ComingSoonProps from "./types";

export default function ComingSoon(props: ComingSoonProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose} sx={{ maxWidth: "none" }}>
      <DialogContent>
        <Typography variant="h2" component="div">
          Coming Soon
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
