import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";

import { history, persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PersistGate } from 'redux-persist/integration/react';

const primaryColor = "#FFFFFF";
const secondaryColor = "#333333";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Lato",
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        container: ({ ownerState }) => {
          if (!ownerState.className) {
            return {
              backgroundColor: primaryColor,
            };
          }
          if (ownerState.className === "primary") {
            return {
              backgroundColor: primaryColor,
            };
          }
          if (ownerState.className === "noBg") {
            return {};
          }
          return {
            backgroundColor: secondaryColor,
          };
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          fontSize: "16px",
          textTransform: "none",
          backgroundColor: primaryColor,
          color: secondaryColor,
        },
        contained: {
          backgroundColor: secondaryColor,
          color: primaryColor,
          width: "200px",
          height: "60px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: secondaryColor,
        },
        paper: {
          borderRadius: "0px !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: secondaryColor,
          color: primaryColor,
        },
        divider: {
          borderBottom: `1px dashed ${primaryColor}`,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "none",
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>
          <PersistGate loading={null} persistor={persistor}>
              <App />
          </PersistGate>
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
