import { Button, Divider, Grid, styled, Typography } from "@mui/material";
import React from "react";
import StyleBox from "../../components/style_box/StyleBox";
import "./Landing.css";
import UploadMenu from "../../components/upload_menu/UploadMenu";
import ComingSoon from "../../components/coming_soon/ComingSoon";

const CustomizedTypography = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
`;

function LandingPage() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isComingSoon, setComingSoon] = React.useState(false);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openComingSoon = () => {
    setComingSoon(true);
  };

  const closeComingSoon = () => {
    setComingSoon(false);
  };

  return (
    <StyleBox>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="primary"
        sx={{
          height: {
            xs: `calc(100vh - 250px)`,
            sm: `600px`
          }
        }}
      >
        <ComingSoon open={isComingSoon} onClose={closeComingSoon} />
        <Grid item sx={{
          width: {
            xs: `calc(100vw - 20px)`,
            sm: `340px`,
          }
        }}>
          <CustomizedTypography align="center">
            Mirror, Mirror,
          </CustomizedTypography>
          <CustomizedTypography align="center">
            Which{" "}
            <CustomizedTypography color="#a562df" variant="caption">
              shoes
            </CustomizedTypography>{" "}
            match
          </CustomizedTypography>
          <CustomizedTypography align="center">the best?</CustomizedTypography>
        </Grid>
        <Grid item sx={{ marginBottom: "16px", marginTop: "75px" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ paddingX: "41px", paddingY: "15px", fontSize: "16px" }}
            onClick={openComingSoon}
          >
            Take a Style Quiz
          </Button>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Divider variant="middle" />
        </Grid>
        <Grid item sx={{ marginTop: "16px", marginBottom: "9px" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ paddingX: "34px", paddingY: "15px" }}
            onClick={openMenu}
          >
            Upload your Outfit
          </Button>
          <UploadMenu anchorEl={anchorEl} onClose={closeMenu} />
        </Grid>
        <Grid item>
          <Typography align="center" sx={{ fontSize: "16px" }}>
            Or Drop it here
          </Typography>
        </Grid>
      </Grid>
    </StyleBox>
  );
}

export default LandingPage;
